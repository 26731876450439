@media only print {
    .no-print {
        display: none;
        width: 0;
        height: 0;
    }

    .divToPrint {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 15px;
        font-size: 14px;
        line-height: 18px;
        z-index: 99999;
    }

    .chartPrint {
        position: relative;
        right: 40%;
    }
}