body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Lemon/Milk';
	font-weight: normal;
	src: url('../fonts/LemonMilk.woff');
}

@font-face {
	font-family: 'Lemon/Milk';
	font-weight: bold;
	src: url('../fonts/LemonMilkbold.woff');
}

@font-face {
	font-family: 'Lemon/Milk';
	font-weight: 200;
	src: url('../fonts/LemonMilklight.woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backdrop-loading-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0.5;
  z-index: 5000
}

.backdrop-loading-container-darker {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  opacity: 0.7;
  z-index: 5000
}

*.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.MuiTypography-h2, .MuiTypography-h3, .MuiTypography-h4 {
  font-family: 'Lemon/Milk';
}

.MuiPaper-root {
  color: #19174a;
}

.MuiTypography-colorPrimary {
  color: #1fb2e7;
}